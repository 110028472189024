import axios from "axios";
import React from "react";

const Medzsites = () => {
  const imageArray = [
    "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/medzsites/6.png",
    "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/medzsites/8.png",
    "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/medzsites/10.png",
    "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/medzsites/11.png",
    "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/medzsites/12.png",
  ];

  const getTrackApi = async () => {
    try {
      await axios.get(`https://pegalworld.com/api/visitor`);
    } catch (error) {
      console.error(error);
    } finally {
      window.location.href = "https://mostexpensive.in/";
    }
  };

  return (
    <div onClick={() => getTrackApi()}>
      <div className="container mx-auto cursor-pointer my-5">
        <div className="flex justify-center items-center my-4 flex-col">
          <img
            src="https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/medzsites/16.png"
            className="rounded-xl"
          />
          <h1 className="md:text-[30px] text-[20px] text-[#000] font-semibold my-6">
            Touch me Now and you get Surprise
          </h1>
          <button
            onClick={() => getTrackApi()}
            className="mt-2 py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-black text-white"
          >
            Touch me now!
          </button>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 pt-10 border-t-2">
          {imageArray.map((image, index) => (
            <div
              key={index}
              className="flex flex-col bg-white border w-fit shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70"
            >
              <img
                className="w-[400px] h-auto rounded-t-xl"
                src={image}
                alt="Card Image"
              />
              <div className="p-4 md:p-5">
                <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                  You wanna Surprise?
                </h3>
                <button
                  className="mt-2 py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-black text-white"
                  onClick={() => getTrackApi()}
                >
                  Touch me now!
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Medzsites;
